import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from "gatsby-image"
import { Link } from 'gatsby'

const FrontPortfolio = () => {
  const data = useStaticQuery(graphql`
    query FrontPortfolioQuery {
      allNodePortfolio(filter: {promote: {eq: true}}, limit: 3) {
        edges {
          node {
            title
            id
            relationships {
              field_seiten_img {
                relationships {
                  field_media_image {
                    localFile {
                      childImageSharp {
                        fixed(width: 200, height: 200) {
                          ...GatsbyImageSharpFixed_withWebp
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <section id="portfolio" className="main special">
      <header className="major">
        <h2>Crabbers Kunden</h2>
      </header>
      <ul className="features">
        { data.allNodePortfolio.edges.map((feature) => 
          <li key={feature.node.id}>
            <span className="icon major">
              <Img fixed={feature.node.relationships.field_seiten_img.relationships.field_media_image.localFile.childImageSharp.fixed} />
            </span>
            <h3>{feature.node.title}</h3>
          </li>
        ) }
      </ul>
      <footer className="major">
        <ul className="actions">
          <li>
            <Link to="/portfolio" className="button">
              Mehr
            </Link>
          </li>
        </ul>
      </footer>
    </section>
  )
} 

export default FrontPortfolio