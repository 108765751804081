import React from 'react'

const Affiliates = () => {
  return (
    <div className="affiliate-wrapper">

      <div className="content amazon">

        <iframe src="https://rcm-eu.amazon-adsystem.com/e/cm?o=3&p=42&l=ez&f=ifr&linkID=0e40512caa133eff7ed4c137a43e3350&t=krabbe-21&tracking_id=krabbe-21" width="234" height="60" scrolling="no" border="0" marginwidth="0" frameborder="0"></iframe>

      </div>
    </div>
  )
} 

export default Affiliates