import React from 'react'
import Scrollspy from 'react-scrollspy'
import Scroll from './Scroll'

const NavFront = (props) => (
  <nav id="nav" className={props.sticky ? 'alt' : ''}>
  <Scrollspy items={ ['intro', 'portfolio', 'blog', 'contact'] } currentClassName="is-active" offset={-300}>
    <li>
      <Scroll type="id" element="intro">
        <a href="#intro" className="navlink">Start</a>
      </Scroll>
    </li>
    <li>
      <Scroll type="id" element="portfolio">
        <a href="#portfolio" className="navlink">Portfolio</a>
      </Scroll>
    </li>
    <li>
        <a href="/blog" className="navlink">Blog</a>
    </li>
    <li>
      <Scroll type="id" element="contact">
        <a href="#contact" className="navlink">Kontakt</a>
      </Scroll>
    </li>
  </Scrollspy>
  </nav>
)

export default NavFront
