import React from 'react'
import Helmet from 'react-helmet'
import { Waypoint } from 'react-waypoint'
import Header from '../components/Header'
import Layout from '../components/layout'
import NavFront from '../components/NavFront'
import FrontIntro from '../components/FrontIntro'
import FrontPortfolio from '../components/FrontPortfolio'
import ContactForm from '../components/ContactForm'
import Affiliates from '../components/Affiliates'

class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      stickyNav: false,
    }
  }

  _handleWaypointEnter = () => {
    this.setState(() => ({ stickyNav: false }))
  }

  _handleWaypointLeave = () => {
    this.setState(() => ({ stickyNav: true }))
  }

  render() {
  // console.log( this )
    return (
      <Layout>
        <Helmet title="Crabber - Webservice" />

        <Header />

        <Waypoint
          onEnter={this._handleWaypointEnter}
          onLeave={this._handleWaypointLeave}
        ></Waypoint>
        <NavFront sticky={this.state.stickyNav} />

        <div id="main" className="fp">

          <FrontIntro />

          <FrontPortfolio />

          <section id="contact" className="main contact">

            <header className="major">
              <h2>Kontakt</h2>
              <p>
                Für Fragen, Anregungen und sonstiges nutze bitte das Kontaktformular. Wir melden uns umgehend zurück.
              </p>
            </header>

            <ContactForm />

          </section>

          <section id="affiliates" className="main affiliates">

            <header className="major">
              <h2>Affiliates</h2>
            </header>

            <Affiliates />

          </section>

        </div>
      </Layout>
    )
  }
}

export default Index
