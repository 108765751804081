import React from 'react'
import { Link } from "react-scroll";
import logo from '../assets/images/crabber-logo.svg';

const Header = (props) => (
  <header id="header" className="alt">
    <span className="logo"><img src={logo} alt="" /></span>
    <div className="page-title-wrap">
      <h1 className="site-title">Crabber Webservice</h1>
      <p className="site-slogan">Wir erstellen und betreuen Dein Web-Projekt!</p>
    </div>
    <Link
      to="main"
      smooth={true}
      duration= {700}
      className="button scroll-button"
    ><i className="icon fa-angle-down" style={{fontSize:`4em`}}></i>
    </Link>
  </header>
)

export default Header
