import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from "gatsby-image"
import Scroll from './Scroll'

const FrontText = () => {
  return (
    <StaticQuery
      query = { graphql`
        query FrontpageQuery {
          nodePage(promote: {eq: true}) {
            id
            title
            body {
              processed
            }
          }
          file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "fp-image.png"}) {
            childImageSharp {
              fixed(width: 300, height: 300) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
        }
      `}
      render = { data => (
        <section id="intro" className="main">
          <div className="spotlight">
            <div className="content">
              <header className="major">
                <h2>{data.nodePage.title}</h2>
              </header>
              <div dangerouslySetInnerHTML={{ __html: data.nodePage.body.processed }} />
              <ul className="actions">
                <li>
                  <Scroll type="id" element="contact">
                    <a href="#contact" className="button">Kontakt</a>
                  </Scroll>
                </li>
              </ul>
            </div>
            <span className="image">
              <Img fixed={data.file.childImageSharp.fixed} />
            </span>
          </div>
        </section>
      )}
    />
  )
} 

export default FrontText